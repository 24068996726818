import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type metaType = Array<{
  property?: string;
  name?: string;
  content: string;
}>;
type Props = {
  title: string;
  description?: string;
  lang?: string;
  meta?: metaType;
  image?: {
    src: string;
    height: number;
    width: number;
  };
  pathname: string;
};

interface QueryData {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      siteUrl: string;
    };
  };
  contentfulSeo: {
    ogImage: {
      fluid: {
        src: string;
      };
    };
  };
}

const SEO = ({
  description = '',
  lang = 'en',
  meta = [],
  image: metaImage,
  title,
  pathname,
}: Props) => {
  const { site, contentfulSeo }: QueryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        contentfulSeo {
          ogImage {
            fluid {
              src
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  const defaultMeta: metaType = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(
    metaImage
      ? ([
          {
            property: 'og:image',
            content: contentfulSeo.ogImage.fluid.src,
          },
          {
            property: 'og:image:width',
            content: metaImage.width,
          },
          {
            property: 'og:image:height',
            content: metaImage.height,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ] as any)
      : [
          {
            name: 'twitter:card',
            content: 'summary',
          },
        ]
  );

  const propMeta: metaType = defaultMeta.concat(meta || []);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={canonical ? [{ rel: 'canonical', href: canonical }] : []}
      meta={defaultMeta.concat(propMeta)}
    />
  );
};

export default SEO;
